import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {StorageService} from "../services/storage.service";
import { HttpService } from 'app/service/http/http.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthorizatedUserGuard implements CanActivate {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private _http: HttpService,
    private toastr: ToastrService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const permission = route.data["permission"];
    let canActivate: boolean;

    if (!permission) throw new Error('Permissions is not setup!');
    if (!permission.only) throw new Error('Roles are not setup!');

    let modulesAccess = this.storageService.getPermissions();
    if (modulesAccess.length == 0){
      // Si se recargo la pagina y no se tiene en el servicio el array de modulos
      return this._http.getMethod([], "user/permissions")
        .pipe(
          // Si la petición es exitosa se puede proceder
          map((res) => {
            // Asignamos a nuestro servicio storageService
            this.storageService.setPermissions(res);
            let canActivate: boolean = res.includes(permission.only);
            if (!canActivate) {
              this.toastr.error('No tienes permisos para realizar está acción.', 'Denegado');
              this.router.navigate([permission.redirectTo]);
            }
            return canActivate;
          }),
          // Si la peticion tiene un error de estado >= 400 se dirige al login
          catchError( () => {
            this.router.navigate([permission.redirectTo]);
            return of(false);
          })
      );
    } else{
      // Si ya está el array de modulos
      canActivate = modulesAccess.includes(permission.only);
      if (!canActivate) {
        this.toastr.error('No puedes realizar ésta acción.', 'Sin Permisos');
        this.router.navigate([permission.redirectTo]);
      }
      return canActivate;
    }
  }  

}