import {style, state, animate, transition, trigger} from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedDataService } from 'app/core/services/shared-data.service';
import { AuthenticationService } from 'app/session/loginone/shared/authentication.service';
import swal from'sweetalert2';
import { ToastrService } from 'ngx-toastr';
declare var $ : any;

@Component({
  selector: 'app-alas-order-tracking',
  templateUrl: './alas-order-tracking.component.html',
  styleUrls: ['./alas-order-tracking.component.scss'],
  animations: [
    trigger('slideIn', [state('*', style({ 'overflow-y': 'hidden' })),state('void', style({ 'overflow-y': 'hidden' })),transition('* => void', [style({ height: '*' }),animate(250, style({ height: 0 }))]),transition('void => *', [style({ height: '0' }),animate(250, style({ height: '*' })) ])]),
    trigger('fadeInOut', [ transition(':enter', [ style({opacity:0}),animate(500, style({opacity:1})) ]), transition(':leave', [ animate(500, style({opacity:0})) ]) ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class AlasOrderTrackingComponent implements OnInit {

  submitted = false;
   orderForm: FormGroup;
   loading = false;
   viewDetails = false;
   defaultStepIndex = 0;
   navBarLocation = "top";
   detailsOrderData: any;

   constructor(
      private router: Router,
      private formBuilder : FormBuilder,
      public translate: TranslateService,
      private route: ActivatedRoute,
      private sharedData: SharedDataService,
      private authenticationService: AuthenticationService,
      private toastr: ToastrService
   ) {
      this.toastr.toastrConfig.timeOut = 5000;
   }

   ngOnInit(){
      $('[data-toggle="tooltip"]').tooltip();

      // Settear detailOrderData
      this.setterDetails();
      // capturar parametro get
      let order = this.route.snapshot.paramMap.get('id');
      // Form validations
      this.orderForm = this.formBuilder.group({
         order: [order, [Validators.required]]
      });

      if (order) this.submit();

      if (window.matchMedia("(min-width: 600px)").matches) {
         /* La pantalla tiene al menos 600 píxeles de ancho */
       } else {
         /* La pantalla tiene menos de 600 píxeles de ancho */
         this.navBarLocation = "left";
      }
   }

   // convenience getter for easy access to form fields
   get f() { return this.orderForm.controls; }
   
   //Submit
   submit() {
      this.submitted = true;
      if (this.orderForm.invalid){
         // Efecto Shake
         this.sharedData.effectAnimation('animation-object'); return;
      } 

      this.loading = true;
      let id = this.orderForm.value.order;
      this.authenticationService.getMethod('web/tracking/alas/155668678-2-2018DV84/' + id).subscribe((data)=>{
         // Correct Tracking
         this.loading = false;
         this.viewDetails = true;

         let info = data.json();
         history.pushState(null, "", "session/order/client/" + info.ExternalReference); // url con id
         console.log("info", info);
         this.detailsOrderData = info;
         //this.detailsOrderData.status = "ME Facilities";
         this.defaultStepIndex = info.paso_index; // Numero Proceso
         setTimeout(()=>{
            this.sharedData.effectAnimation('animation-object-order', 'flipInX', 2000);
         }, 30);
       }, err =>{
         console.log("err",err);
         this.loading = false;
         if (err.status == 500){
            swal.fire({ icon: 'error', title: 'Ups...', text: '¡Ha ocurrido un error inesperado con el servidor! Intentalo de nuevo más tarde.'});
         }else{
            let message = JSON.parse(err._body).error;
            if (JSON.parse(err.status) == 400){
               this.submitted = false;
               swal.fire({ icon: 'info', title: 'Ups...', text: '¡No se pudo encontrar información para la referencia solicitada! Verifique su número de referencia.'});
            }else{
               this.toastr.error("Ha ocurrido un error inesperado.");
            }
         }
       } 
      );
   }

   closeAlert(){
      
   }

   setterDetails(){
      this.detailsOrderData = {
         id_orden: "123456",
         ExternalReference: "",
         Nombre: "",
         WebMedioDePago: "",
         Telefonos: "",
         TransporteID: "",
         movil: "",
         contacto: "",
         total: 0,
         one_destination: true,
         historical: [
            { StatusWeb: "", FechaCrea: "" }
         ],
         origen: [
            {
               BarrioDestino: "PANAMA VIEJO:PANAMA METRO",
               Concepto: "RETIRAR PEDIDOS",
               Direccion: "MULTIENTREGA PANAMA",
               FirmaRecibe: "",
               ID: "411667",
               Item: "1",
               contacto: "",
               documentos: "",
               image: []
            }
         ],
         destino: {
            AppEventNotes: "",
            BarrioDestino: "LAS ACACIAS:PANAMA METRO",
            Concepto: "CLARIBEL HERNANDEZ 	 6809-7192",
            Direccion: "VILLA LAS ACACIAS CASA 271, ENTRANDO POR EL SUPER 99 PARADOR DESPUES DE PRODUCTOS LA DOÑA PRIMERA ENTRADA A LA IZQUIERDA.",
            Estado: "",
            FirmaRecibe: "",
            ID: "411668",
            Item: "2",
            Observaciones: "",
            contacto: "",
            documentos: "",
            image: []
         }
      };
   }

   gotoback(){
      this.submitted = false;
      this.viewDetails = false;
      this.setterDetails(); //this.detailsOrderData = [];
      history.pushState(null, "", "session/order/client"); // url SIN el id
   }
   
   getString(value: string){
      if (value) return value;
      return "No information.";
   }

}
