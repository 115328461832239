import {Injectable} from "@angular/core";
import { Router } from '@angular/router';
import { ReplaySubject } from "rxjs";
import { HttpService } from 'app/service/http/http.service';
import { HttpService2 } from 'app/service/http/http.service2';

export class Session {
  public token: string;
  public user: any;
  public grl_persona: any;
  public permissions: [];
}

export class Session2 {
  public token: string;
  public user: any;
  public grl_persona: any;
  public permissions: [];
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;
  private currentSession : Session = null;
  isLoggedIn = false;
  userData: any;
  private permissionModules = [];

  constructor(
    private router: Router,
    private _http: HttpService,
    private _http2: HttpService2
  ) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.permissionModules = session.permissions;
    this.isLoggedIn = true;
    this.localStorageService.setItem('access_token', session.token);
    this.localStorageService.setItem('currentUser', JSON.stringify(session));
  }

  setCurrentSession2(session: Session2): void {
    this.currentSession = session;
    this.isLoggedIn = true;
    this.localStorageService.setItem('authorization', session);
  }

  getPermissions(){
    return this.permissionModules;
  }
  setPermissions(data){
    this.permissionModules = data;
  }

  loadSessionData(): Session{
    var sessionStr = this.localStorageService.getItem('currentUser');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('access_token');
    this.currentSession = null;
    this.isLoggedIn = false;
    this.permissionModules = [];
  }

  getCurrentUser(): any {
    var session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };
  getCurrentGrl(): any {
    var session: Session = this.getCurrentSession();
    return (session && session.grl_persona) ? session.grl_persona : null;
  };

  getLocalStorageUser(){
    this.userData = JSON.parse(localStorage.getItem("currentUser"));
    if(this.userData) {
       this.isLoggedIn = true;
       return true;
    } else {
       this.isLoggedIn = false;
       return false;
    }
  }

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  };

  logout(withRequest: Boolean = false): void{
    let token = localStorage.getItem("access_token");
    if (withRequest){
      this._http.logoutMethod(token).subscribe((data)=>{
      });
    }
    this.removeCurrentSession();
    // this.router.navigate(['./session/login']);
    window.location.href="./";
    //this.notifyService.showNotify('Su sesión ha expirado', 'info', 1000);
  }
}
