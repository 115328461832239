import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpService } from 'app/service/http/http.service';
import { HttpService2 } from 'app/service/http/http.service2';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private basePath = '';
  private basePath2 = '';

  constructor(
    private http: HttpClient, 
    private _http: Http, 
    private httpService: HttpService,
    private httpService2: HttpService2
  ) {
    this.basePath = this.httpService.dominio;
    this.basePath2 = this.httpService2.dominio2;
  }

  login(loginObj: any): Observable<any> {
        
    return this._http.post(this.basePath + 'token/generate', loginObj).map((resp) => {
      //console.log(resp['_body']);      
      return resp;
    }, err => {
      return err;
    })
    
  }

  login2(loginObj: any): Observable<any> {
    return this._http.post(this.basePath2 + 'loginClient', loginObj).map((resp) => {
      return resp;
    }, err => {
      return err;
    })
  }

  logout() {
    let token = localStorage.getItem("access_token");
    if (token) this.httpService.logoutMethod(token);
  }
  
  getMethod(endpoint: any){
    return this._http.get(this.basePath + endpoint).map((resp) => {
      return resp;
    }, err => {
      return err;
    })
  }

}
