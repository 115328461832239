import { Injectable } from '@angular/core';

export class Order {
  origin: {
    barrio: string;
    direccion: string;
    observaciones: string;
    contacto: string;
    documentos: string;
  };
  destinations?: [];
}

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  intervalCards: any;
  intervalChecks: any;
  intervalInternational : any;
  orderData: Order = {
    origin: {
      barrio: "",
      direccion: "",
      observaciones: "",
      contacto: "",
      documentos: ""
    },
    destinations: []
  }
  headerChecksData: any = {
    TotMeets: 0,
    TotMeetsSinChq: 0,
    TotMeetsConChq: 0,
    ChqCant: 0,
    ChqMonto: 0,
    TotGest: 0,
    TotNoMeets: 0,
  };
  headerCardsData: any = {
    totalAsignada: 0,
    totalEntregadas: 0,
    totalNoEntregadas: 0,
    totalNoEntregadasDevolucion: 0,
    proceso: 0,
  };

  constructor() { }

  public clearAllIntervals(){
    if (this.intervalChecks) { clearInterval(this.intervalChecks); }
    if (this.intervalCards) { clearInterval(this.intervalCards); }
  }

  public effectAnimation(idString: string, effect: string = 'shake', time: number = 1000){
    // Efecto Shake
    document.getElementById(idString).className = '';
    document.getElementById(idString).classList.add('animated', effect);
    setTimeout(()=>{ document.getElementById(idString).className = ''; }, time);
  }

}
