import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-whatsapp-multi',
  templateUrl: './whatsapp-multi.component.html',
  styleUrls: ['./whatsapp-multi.component.scss']
})
export class WhatsappMultiComponent implements OnInit {

  constructor(private _location: Location) { }

  ngOnInit() {
    window.open('https://api.whatsapp.com/send?phone=50768868584&text=Soporte Multientrega', '_blank');
    this._location.back();
    window.history.go(1);
    setTimeout(function(){
      location.reload();
    }, 1000);
    
    
  }

}
